import { supportedLngs } from '../utils/i18n';

const baseUrl = process.env.REACT_APP_CDN_API_ENDPOINT;

const defaultLng = 'en';

const fallbackLng = (lng) => (supportedLngs.includes(lng) ? lng : 'en');

export default {
    getProductJson: (sku, lng = defaultLng) => `/files-${fallbackLng(lng)}/products/${sku}.json`,
    // getProductJson: (sku, lng = defaultLng) => `/files/products/${sku}.json`,
    getRoastingPlant: (organizationCode, lng = defaultLng) =>
        `/files-${fallbackLng(lng)}/roastingplants/${organizationCode}.json`,
    getCountryDetail: (countryCode, lng = defaultLng) => `/files-${fallbackLng(lng)}/origins/${countryCode}.json`,
    getFarmerProfile: (countryCode, lng = defaultLng) =>
        `/files-${fallbackLng(lng)}/profile-corereservefarmer/${countryCode}.json`,
    getRoasterProfile: (organizationCode, lng = defaultLng) =>
        `/files-${fallbackLng(lng)}/profile-roaster/${organizationCode}.json`,
    getPartnerProfile: (sku, lng = defaultLng) => `/files-${fallbackLng(lng)}/profile-partner/${sku}.json`,
    getBagImage: (sku) => `/images/bag/${sku}.png`, // ================
    getBannerImage: (sku) => `/images/banner/${sku}.jpg`,
    getPosterImage: (sku) => `/images/poster/${sku}.jpg`,
    getRoastingPlantImage: (organizationCode, lng = defaultLng) => `/images/roastingplant/${organizationCode}.jpg`,
    getFarmerProfileImage: (id) => `/images/producerprofile/${id}`,
    getRoasterProfileImage: (id) => `/images/roasterprofile/${id}`,
    getPartnerProfileImage: (id) => `/images/partnerprofile/${id}`,
    getCafePracticeStats: (id) => `/vrsapplications/${id}.json`,
    getHAFarmBannerImage: (roast) => `/images/HAFarm/${roast}/banner.jpg`,
    getHAFarmThumbnailImage: (roast) => `/images/HAFarm/${roast}/thumbnail.jpg`,
    getHAFarmRoastProfileImage: (img) => `/images/HAFarm/profiles/${img}`,
    getHAFarmImage: (img) => `/images/HAFarm/${img}`,
};
